$coverSquareWidth: 120px;

.librarycard {
    margin: 10px 0;

    h3 {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 8px;

        a {
            font-weight: bold;
        }
    }

    .badges {
        text-align: right;
        
        .badge {
            border-radius: 0;
            margin-right: 5px;

            a {
                color: white;
                text-transform: lowercase;
            }
        }
    }

    .inner {
        position: relative;
        background-color: #eee;
        padding: 5px;
        margin: 0.5em;
        min-height: $coverSquareWidth + 15px;
        height: 100%;
        width: 100%;
    }

    .cover-art {
        display: block;
        height: $coverSquareWidth;
        width: $coverSquareWidth;
        border: 1px solid gray;
        margin-right: 10px;
        
        img {
            border: 1px solid gray;
            max-height: $coverSquareWidth;
            max-width: $coverSquareWidth;
            height: auto;
            width: auto;
        }

        &.placeholder {
            opacity: 0.3;
        }
    }

    .card-cover-image {
        text-align: center;
        border: 0;
    }

    .storydata {

        .author {
            font-size: 18px;
        }

        .blurb {
            font-family: sans-serif;
            line-height: 1.2em;
            white-space: pre-wrap;
        }
    }

    .story-permalink {
        opacity: 0.5;
    }

    .credits-icon {
        color: #17a;
        cursor: default;
        display: inline-block;
    }
}

