@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

body, html {
    font-family: 'Noto Serif', serif;
}

a {
    color: #003b7a;
}

#root
{
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > .container {
        padding-bottom: 1rem;
    }
}