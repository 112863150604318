$border-color: #333;

.file-upload-field {
    border-radius: 3px;
    color: #555;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    height: 200px;
    justify-content: center;
    margin: 1em auto;
    padding: 1em;

    &:not(.preview) {
        background-color: #ecf0f1;
        border: 3px dashed $border-color;
        cursor: pointer;
        opacity: 0.9;
    }

    p {
        text-align: center;
        width: 100%;
    }

    &.image-upload {
        max-width: 300px;

        @media (max-width: 550px) {
            font-size: 18px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    &.game-file-upload {
        &.preview {
            border: 3px solid $border-color;

            .reset-upload {
                align-self:flex-end;
                text-align: right;
            }
        }
    }
}